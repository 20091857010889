<!-- 查看详情 -->
<template>
  <div>
    <back />
    <div class="riderDetail" v-loading="loading">
      <h3 class="title">申请详情</h3>
      <el-row class="row">
        <el-col :span="6">
          <div class="col"><span class="txt">骑手编号:</span>{{ detail?.riderNum }}</div>
          <div class="col"><span class="txt">学号:</span>{{ detail?.studentId }}</div>
          <div class="col"><span class="txt">手机号:</span>{{ detail?.phoneNum }}</div>
        </el-col>
        <el-col :span="6">
          <div class="col"><span class="txt">姓名:</span>{{ detail?.riderName }}</div>
          <div class="col"><span class="txt">审核时间:</span>{{ detail?.updatedTime ? detail.updatedTime : '-' }}</div>

          <div class="col"><span class="txt">学校名称:</span>{{ detail?.schoolName }}</div>

        </el-col>
        <el-col :span="6">
          <div class="col"><span class="txt">身份证号:</span>{{ detail?.idCard }}</div>
          <div class="col"><span class="txt">学校名称:</span>{{ detail?.schoolName }}</div>
        </el-col>
        <el-col :span="6">
          <div class="col"><span class="txt">提交时间:</span>{{ detail?.createdTime }}</div>
          <div class="col"><span class="txt">状态:</span>{{ getStatusText(detail?.status) }}</div>
        </el-col>
      </el-row>
      <el-row class="row">
        <el-col class="col red" v-if="detail?.reason"><span class="txt">不通过原因:</span>{{ detail?.reason }}</el-col>
      </el-row>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      detail: {},
      userId: '',
    }
  },
  created() {
    this.userId = this.$route.query.userId;
    this.getApplyDetail();
  },
  methods: {
    getApplyDetail() {
      this.loading = true;
      this.$axios.get(`${this.$api.getRiderUserDetails}/${this.userId}`).then((res) => {
        if (res.data.code == 100) {
          this.detail = res.data.result;
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
    getStatusText(status) {
      switch (status) {
        case '0':
          return "待审核";
        case '1':
          return "审核通过";
        case '2':
          return "审核不通过";
        default:
          return " ";
      }
    },
  }

}
</script>

<style lang="scss" scoped>
.riderDetail {
  padding: 20px;

  .title {
    margin-bottom: 25px;
  }

  .row {
    color: #707070;
  }

  .col {
    margin-bottom: 25px;
  }

  .red {
    color: red;
  }

  .txt {
    margin-right: 8px;
  }

}
</style>
